<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col md="5" sm="6" cols="12">
          <div class="d-flex display-header">
            <v-select dense outlined hide-details label="เลขที่บิล" :items="item" class="me-2 pd_responsive"></v-select>
            <v-select
              dense
              outlined
              hide-details
              label="ร่าง"
              :items="item"
              v-model="stateDraft"
              v-if="stateDraft === true"
              class="bg-draft pd_responsive"
            ></v-select>
            <v-select
              dense
              outlined
              hide-details
              label="ยังไม่จ่าย"
              :items="item"
              v-model="stateNotPay"
              v-if="stateNotPay === true"
              class="bg-notPay pd_responsive"
            ></v-select>
            <v-select
              dense
              outlined
              hide-details
              label="โอนแล้ว"
              :items="item"
              v-model="statePay"
              v-if="statePay === true"
              class="bg-pay pd_responsive"
            ></v-select>
            <v-select
              dense
              outlined
              hide-details
              label="เตรียมส่ง"
              :items="item"
              v-model="stateMakeSend"
              v-if="stateMakeSend === true"
              class="bg-makeSend pd_responsive"
            ></v-select>
            <v-select
              dense
              outlined
              hide-details
              label="ส่งแล้ว"
              :items="item"
              v-model="stateSend"
              v-if="stateSend === true"
              class="bg-send pd_responsive"
            ></v-select>
            <v-btn color="primary" class="mg-left" @click="gotoOpenBill()">
              <v-icon class="me-1">
                {{ icons.mdiPlus }}
              </v-icon>
              เปิดบิลใหม่
            </v-btn>
          </div>
        </v-col>
        <v-col md="7" sm="6" cols="12">
          <div class="d-flex justify-md-end display_respon">
            <!-- see more bill online -->
            <v-btn @click="gotoBill()" color="warning" class="me-2 pd_responsive">
              <v-icon class="me-1" size="18">
                {{ icons.mdiFileDocument }}
              </v-icon>
              <p class="mb-0">ดูบิลออนไลน์</p>
            </v-btn>
            <!-- send bill -->
            <v-btn color="warning" class="me-2 pd_responsive">
              <v-icon class="me-1" size="18">
                {{ icons.mdiSend }}
              </v-icon>
              <p class="mb-0">ส่งบิล</p>
            </v-btn>
            <!-- send message -->
            <!-- <v-btn @click="gotoAll()" color="warning" class="me-2">
              <p class="mb-0">ส่งข้อความ</p>
            </v-btn> -->
            <!-- tax -->
            <v-btn @click="gotoTax()" color="warning">
              <v-icon class="me-1" size="18">
                {{ icons.mdiPrinter }}
              </v-icon>
              <p class="mb-0">ใบกำกับภาษี / ใบเสร็จ</p>
            </v-btn>
          </div>
        </v-col>
        <v-col md="4" sm="4" cols="12" class="text-center mdRespon">
          <p>รายการสินค้า</p>
        </v-col>
        <v-col md="4" sm="4" cols="12" class="text-center mdRespon">
          <p>การชำระเงิน</p>
        </v-col>
        <v-col md="4" sm="4" cols="12" class="text-center mdRespon">
          <p>การจัดส่ง</p>
        </v-col>
      </v-row>
      <div class="d-flex justify-sm-space-around mt-5 mb-3 showContentmd">
        <h3>รายการสินค้า</h3>
        <h3>การชำระเงิน</h3>
      </div>
      <hr class="showContentmd" />
      <v-row class="warpper_Content">
        <!-- content 1 -->
        <v-col md="4" sm="6" cols="12" class="border_right">
          <h3 class="mb-5 showMobile">รายการสินค้า</h3>
          <hr class="mb-5 showMobile" />
          <div class="wapper_content1">
            <li v-for="item in this.$store.state.mockData" :key="item.id">
              <v-row>
                <v-col md="2" sm="12" cols="12" class="img_proauctRes">
                  <img :src="item.img" alt="test" />
                </v-col>
                <v-col md="5" sm="12" cols="12">
                  <v-text-field
                    v-if="buttonStart === true || buttonGoToNotPay === true || changEditData === true"
                    label="ชื่อสินค้า"
                    type="text"
                    outlined
                    dense
                    hide-details
                    placeholder="ชื่อสินค้า"
                    class="mt-1"
                    v-model="item.nameProduct"
                  ></v-text-field>
                  <p v-if="buttonStart === false && buttonGoToNotPay === false && changEditData === false">
                    {{ item.nameProduct }}
                  </p>
                  <v-select
                    v-if="buttonStart === true || buttonGoToNotPay === true || changEditData === true"
                    outlined
                    dense
                    hide-details
                    label="แบบ"
                    :items="item.attachFile"
                    v-model="item.attachFile"
                    class="mt-2"
                  ></v-select>
                  <p v-if="buttonStart === false && buttonGoToNotPay === false && changEditData === false">
                    {{ item.attachFile }}
                  </p>
                  <v-textarea
                    v-if="buttonStart === true || buttonGoToNotPay === true || changEditData === true"
                    label="ข้อมูลสินค้า"
                    hide-details
                    auto-grow
                    outlined
                    rows="1"
                    row-height="15"
                    class="mt-2"
                    v-model="item.description"
                  ></v-textarea>
                  <p
                    v-if="
                      buttonStart === false &&
                        buttonGoToNotPay === false &&
                        changEditData === false &&
                        changEditData === false
                    "
                  >
                    {{ item.description }}
                  </p>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                  <v-text-field
                    v-if="buttonStart === true || buttonGoToNotPay === true || changEditData === true"
                    v-model="item.price"
                    label="ราคา"
                    type="number"
                    outlined
                    dense
                    hide-details
                    placeholder="ราคา"
                    class="mt-1"
                  ></v-text-field>
                  <p
                    v-if="buttonStart === false && buttonGoToNotPay === false && changEditData === false"
                    class="text-end"
                  >
                    {{ item.price }} บาท
                  </p>
                  <div
                    v-if="buttonStart === true || buttonGoToNotPay === true || changEditData === true"
                    class="d-flex align-center mt-1"
                  >
                    <v-icon v-if="closeIcons" @click="btnClickClose('ลบข้อมูล')" color="error" size="17" class="me-1">
                      {{ icons.mdiCloseCircle }}
                    </v-icon>
                    <v-icon v-if="minusIcons" @click="btnClickMinus('ลบจำนวน')" color="primary" size="17" class="me-1">
                      {{ icons.mdiMinusCircle }}
                    </v-icon>
                    <v-text-field
                      v-model="item.amount"
                      label="จำนวน"
                      type="number"
                      outlined
                      dense
                      hide-details
                      placeholder="จำนวน"
                      class="mt-1"
                    ></v-text-field>
                    <!-- <input type="number" v-model="item.price" placeholder="ราคา (บาท)"> -->
                    <v-icon @click="btnClickPlus(item.id)" color="primary" size="17" class="ml-1">
                      {{ icons.mdiPlusCircle }}
                    </v-icon>
                  </div>
                  <p
                    v-if="buttonStart === false && buttonGoToNotPay === false && changEditData === false"
                    class="text-end"
                  >
                    {{ item.amount }} ชิ้น
                  </p>
                </v-col>
              </v-row>
              <hr class="mt-5 mb-5" />
            </li>
          </div>
          <!-- content bottom -->
          <div v-if="buttonStart === true || buttonGoToNotPay === true" class="mt-10">
            <hr class="mt-5 mb-5" />
            <div class="text-center wapperContentBottom">
              <v-menu top :offset-y="offset">
                <template v-slot:activator="{ on, attrs }">
                  <div class="wapperIconContent">
                    <v-btn color="primary" v-bind="attrs" v-on="on" class="hidden_btn" hide-details>
                      <v-icon class="me-1">
                        {{ icons.mdiPlus }}
                      </v-icon>
                      เพิ่มสินค้า
                    </v-btn>
                  </div>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in itemUpload" :key="index">
                    <v-list-item-title>
                      <!-- <a href="https://www.google.com/" target="_blank">
                        <v-icon size="20" class="me-1 iconButton">
                          {{ item.icons }}
                        </v-icon>
                        {{ item.title }}
                      </a> -->
                      <v-dialog transition="dialog-top-transition" width="1000">
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <v-btn text v-bind="attrs" v-on="on" class="mt-5 testdialog">
                              <v-icon class="me-1">{{ item.icons }}</v-icon>
                              {{ item.title }}
                            </v-btn>
                          </div>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <AddProduct />
                            <v-card-actions class="justify-space-between">
                              <v-btn text @click="dialog.value = false">Close</v-btn>
                              <v-btn color="primary" @click="dialog.value = false">เพิ่มสินค้า</v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item-title>
                    <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="uploadFile" hide-details />
                  </v-list-item-title>
                </v-list>
              </v-menu>
            </div>
          </div>

          <!-- content bottom content hidden -->
          <div v-if="buttonStart === false && buttonGoToNotPay === false" class="mt-10">
            <hr class="mt-5 mb-5" />
            <div class="text-center wapperContentBottom">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="wapperIconContent">
                    <v-btn
                      v-show="!buttonGoToSend"
                      class="hidden_btn"
                      hide-details
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="clickEditData"
                    >
                      <v-icon class="me-1">
                        {{ icons.mdiPlus }}
                      </v-icon>
                      แก้ไข
                    </v-btn>
                  </div>
                </template>
                <span>
                  บิลนี้มีการแจ้งชำระเงิน หรือ ออกรหัสพัสดุแล้ว <br />
                  การแก้ไขรายการสินค้าในภายหลัง <br />
                  อาจส่งผลให้ยอดสุทธิไม่ตรงกับยอดแจ้งชำระ
                </span>
              </v-tooltip>
            </div>
          </div>

          <v-row>
            <!-- เลือกการส่ง -->
            <v-col md="6" sm="6" cols="12">
              <v-select
                v-if="buttonStart === true || buttonGoToNotPay === true || changEditData === true"
                v-model="selectTransition"
                dense
                outlined
                hide-details
                label="เลือกการส่ง"
                :items="transit"
                class="mt-5"
              ></v-select>
              <p
                v-if="buttonStart === false && buttonGoToNotPay === false && changEditData === false"
                class="mb-0 mt-5"
              >
                {{ selectTransition }}
              </p>
            </v-col>
            <!-- ราคา -->
            <v-col md="6" sm="6" cols="12">
              <v-text-field
                v-if="buttonStart === true || buttonGoToNotPay === true || changEditData === true"
                v-model="priceAdd"
                label="ราคา (บาท)"
                type="number"
                outlined
                dense
                hide-details
                placeholder="ราคา (บาท)"
                class="mt-5"
              ></v-text-field>
              <p
                v-if="buttonStart === false && buttonGoToNotPay === false && changEditData === false"
                class="mb-0 mt-5 text-end"
              >
                {{ priceAdd }}
              </p>
            </v-col>
            <!-- ส่วนลด -->
            <v-col md="6" sm="6" cols="12" class="d-flex align-center">
              <h3>ส่วนลด</h3>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field
                v-if="buttonStart === true || buttonGoToNotPay === true || changEditData === true"
                v-model="discount"
                label="ราคา (บาท)"
                type="number"
                outlined
                dense
                hide-details
                placeholder="ราคา (บาท)"
              ></v-text-field>
              <p
                v-if="buttonStart === false && buttonGoToNotPay === false && changEditData === false"
                class="mb-0 text-end"
              >
                {{ discount }}
              </p>
            </v-col>
            <!-- ยอดสุทธิ -->
            <v-col md="6" sm="6" cols="12" class="d-flex align-center">
              <h3>ยอดสุทธิ</h3>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field
                v-if="buttonStart === true || buttonGoToNotPay === true || changEditData === true"
                v-model="price"
                label="ราคา (บาท)"
                type="number"
                outlined
                dense
                hide-details
                placeholder="ราคา (บาท)"
              ></v-text-field>
              <p
                v-if="buttonStart === false && buttonGoToNotPay === false && changEditData === false"
                class="mb-0 text-end"
              >
                {{ price }}
              </p>
            </v-col>
          </v-row>
        </v-col>

        <!-- content 2 -->
        <v-col md="4" sm="6" cols="12" class="border_right">
          <h3 class="mb-5 showMobile">การชำระเงิน</h3>
          <hr class="mb-5 showMobile" />
          <div v-if="buttonStart === true || buttonGoToNotPay === true || changEditData === true">
            <p class="mb-2">
              วันที่เปิดบิล:
              <span>{{ getDay }}</span>
            </p>
            <p>
              วันที่หมดอายุ:
            </p>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              hide-details
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  label="Date"
                  hint="MM/DD/YYYY format"
                  persistent-hint
                  v-bind="attrs"
                  outlined
                  dense
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
            </v-menu>
            <hr class="mt-3 mb-3" />
            <p class="mb-0">โอนเข้าบัญชี</p>
            <v-select
              dense
              outlined
              hide-details
              label="เลือกธนาคาร"
              :items="bankking"
              v-model="selectBankking"
              class="mt-2"
            ></v-select>

            <!-- ถ้าเลือกพร้อมเพย์ส่วนนี้จะแสดง -->
            <div v-if="selectBankking === 'พร้อมเพย์'">
              <!-- เลขบัญชี -->
              <p class="mb-2 mt-3">เลขที่บัญชี: <span>999-999-9999</span></p>
              <!-- ชื่อบัญชี -->
              <p class="mb-2">ชื่อบัญชี: <span>บัญชีตัวอย่างเพื่อทดสอบ</span></p>
              <!-- เริ่มวันที่โอน -->
              <p class="mb-2">
                วันที่โอน:
              </p>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                hide-details
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Date"
                    hint="MM/DD/YYYY format"
                    persistent-hint
                    v-bind="attrs"
                    outlined
                    dense
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
              </v-menu>
              <!-- จบวันที่โอน -->

              <!-- เวลาโอน -->
              <p class="mb-2">เวลาที่โอน:</p>
              <v-text-field
                v-model="timePay"
                label="เวลาที่โอน"
                type="text"
                outlined
                dense
                hide-details
                placeholder="18:53"
                class="mb-2"
              ></v-text-field>
              <!-- จบเวลาโอน -->

              <!-- ยอดโอน -->
              <p class="mb-2">ยอดโอน:</p>
              <v-text-field
                v-model="allPay"
                label="ยอดโอน"
                type="number"
                outlined
                dense
                hide-details
                placeholder="200"
              ></v-text-field>
            </div>
          </div>
          <!-- content No Payment -->
          <div>
            <!-- ไม่มีพร้อมเพย์ -->
            <p
              v-if="
                buttonStart === false &&
                  buttonGoToNotPay === false &&
                  changEditData === false &&
                  price > 0 &&
                  selectBankking != 'พร้อมเพย์'
              "
              class="text-center mt-5"
            >
              ไม่มีข้อมูลการแจ้งชำระเงินในระบบ
            </p>
            <p
              v-if="
                buttonStart === false &&
                  buttonGoToNotPay === false &&
                  changEditData === false &&
                  price === 0 &&
                  selectBankking === 'พร้อมเพย์'
              "
              class="text-center mt-5"
            >
              ไม่มีข้อมูลการแจ้งชำระเงินในระบบ
            </p>

            <!-- พร้อมเพย์ -->
            <div
              v-if="
                buttonStart === false &&
                  buttonGoToNotPay === false &&
                  changEditData === false &&
                  price > 0 &&
                  selectBankking === 'พร้อมเพย์'
              "
            >
              <p class="text-center">
                ลูกค้าได้แจ้งชำระเงินโดยการโอนเข้าบัญชีต่อไปนี้ผ่านทางบิลออนไลน์ กรุณาตรวจสอบข้อมูลกับธนาคาร
                หากถูกต้องแล้วจึงกดปุ่ม รับชำระ
              </p>
              <hr class="mb-5" />
              <v-row>
                <!-- โอนเข้าบัญชี -->
                <v-col md="4" sm="4" cols="12">
                  <p class="text-end">โอนเข้าบัญชี</p>
                </v-col>
                <v-col md="8" sm="8" cols="12">
                  <div class="d-flex">
                    <img src="https://blog.minhazav.dev/images/post14_image1.png" alt="QR Code Demo" class="imgQR" />
                    <div class="contentQr">
                      <span>พร้อมเพย์</span>
                      <span>999-999-9999</span>
                      <span>บัญชีตัวอย่างเพื่อทดสอบ</span>
                    </div>
                  </div>
                </v-col>
                <!-- จบโอนเข้าบัญชี -->

                <!-- วันเวลาที่โอน -->
                <v-col md="4" sm="4" cols="12" class="pt-0 pb-1">
                  <p class="mb-0 mt-0 text-end">วันเวลาที่โอน</p>
                </v-col>
                <v-col md="8" sm="8" cols="12" class="pt-0 pb-1">
                  <p class="mb-0">
                    <span>
                      {{ dateFormatted }}
                      <span>
                        {{ timePay }}
                      </span>
                    </span>
                  </p>
                </v-col>
                <!-- จบวันเวลาที่โอน -->

                <!-- ยอดโอน -->
                <v-col md="4" sm="4" cols="12" class="pt-0 pb-1">
                  <p class="text-end mb-0">ยอดโอน</p>
                </v-col>
                <v-col md="8" sm="8" cols="12" class="pt-0 pb-1">
                  <p class="mb-0">
                    <span>{{ allPay }} บาท</span>
                  </p>
                </v-col>
                <!-- จบยอดโอน -->

                <!-- สลิป -->
                <v-col md="4" sm="4" cols="12" class="pt-0 pb-1">
                  <p class="text-end">สลิป</p>
                </v-col>
                <v-col md="8" sm="8" cols="12" class="pt-0 pb-1">
                  <p>
                    ไม่ได้แนบสลิปผ่านบิลออนไลน์
                  </p>
                </v-col>
                <!-- จบสลิป -->
              </v-row>
            </div>
          </div>
        </v-col>
        <!-- content 3 -->
        <v-col md="4" sm="12" cols="12" class="top_margin">
          <h3 class="mb-5 showMobile showContentmd">การจัดส่ง</h3>
          <hr class="mb-5 showMobile showContentmd" />
          <v-row>
            <!-- Social Conents -->
            <v-col md="4" sm="6" cols="12" class="pb-0">
              <p class="font-weight-bold mb-0">Facebook</p>
            </v-col>
            <v-col md="8" sm="6" cols="12" class="pb-0">
              <p class="mb-0 pb-0">{{ customer }}</p>
            </v-col>
            <!-- ชื่อผู้รับ -->
            <v-col md="4" sm="6" cols="12" class="pt-3 d-flex align-center">
              <p class="font-weight-bold mb-0 pt-0">ชื่อผู้รับ</p>
            </v-col>
            <v-col md="8" sm="6" cols="12" class="pt-3">
              <p class="mb-0 pt-0">
                <v-text-field
                  v-if="buttonGoToSend === false"
                  v-model="customer"
                  label="ชื่อผู้รับ"
                  type="text"
                  outlined
                  dense
                  hide-details
                  placeholder="ชื่อผู้รับ"
                ></v-text-field>
              </p>
              <p v-if="buttonGoToSend === true">{{ customer }}</p>
            </v-col>
            <!-- เบอร์ติดต่อ -->
            <v-col md="4" sm="6" cols="12" class="pt-1 d-flex align-center">
              <p class="font-weight-bold mb-0 pt-0">เบอร์ติดต่อ</p>
            </v-col>
            <v-col md="8" sm="6" cols="12" class="pt-1">
              <p class="mb-0 pt-0">
                <v-text-field
                  v-if="buttonGoToSend === false"
                  v-model="customerTel"
                  label="เบอร์ติดต่อ"
                  type="tel"
                  outlined
                  dense
                  hide-details
                  placeholder="เบอร์ติดต่อ"
                  maxlength="10"
                ></v-text-field>
              </p>
              <p v-if="buttonGoToSend === true">{{ customerTel }}</p>
            </v-col>
            <!-- ที่อยู่ -->
            <v-col md="4" sm="6" cols="12" class="pt-1 d-flex align-center">
              <p class="font-weight-bold mb-0 pt-0">ที่อยู่จัดส่ง</p>
            </v-col>
            <v-col md="8" sm="6" cols="12" class="pt-1">
              <p class="mb-0 pt-0">
                <v-textarea
                  v-if="buttonGoToSend === false"
                  v-model="address"
                  label="ที่อยู่จัดส่ง"
                  hide-details
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  maxlength="200"
                ></v-textarea>
              </p>
              <p v-if="buttonGoToSend === true">{{ address }}</p>
            </v-col>
            <!-- หมายเหตุ -->
            <v-col md="4" sm="6" cols="12" class="pt-1 d-flex align-center">
              <p class="font-weight-bold mb-0 pt-0">หมายเหตุ</p>
            </v-col>
            <v-col md="8" sm="6" cols="12" class="pt-1">
              <p class="mb-0 pt-0">
                <v-textarea
                  v-if="buttonGoToSend === false"
                  v-model="reason"
                  label="หมายเหตุ"
                  hide-details
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  maxlength="200"
                ></v-textarea>
              </p>
              <p v-if="buttonGoToSend === true">{{ reason }}</p>
            </v-col>
          </v-row>
          <hr class="mt-5 mb-5" />
          <v-row>
            <!-- วิธีการจัดส่ง -->
            <v-col md="4" sm="6" cols="12" class="pb-0">
              <p class="mb-0">วิธีจัดส่ง</p>
            </v-col>
            <v-col md="8" sm="6" cols="12" class="pb-0">
              <p class="mb-0">ไม่ระบุ</p>
            </v-col>
            <!-- รหัสพัสดุ -->
            <v-col md="4" sm="6" cols="12" class="pt-1 d-flex align-center">
              <p class="font-weight-bold mb-0 pt-0">รหัสพัสดุ</p>
            </v-col>
            <v-col md="8" sm="6" cols="12" class="pt-2">
              <v-text-field
                v-if="buttonGoToSend === false"
                v-model="codeProduct"
                label="รหัสพัสดุ"
                type="text"
                outlined
                dense
                hide-details
                placeholder="รหัสพัสดุ"
              ></v-text-field>
              <p v-if="buttonGoToSend === true">{{ codeProduct }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="d-flex justify-space-between style_button">
        <!-- button event next -->
        <div>
          <v-btn text v-model="stateNotPay" v-if="stateNotPay === true" @click="buttonReverse1">ปรับเป็นร่าง</v-btn>
          <v-btn text v-model="statePay" v-if="statePay === true" @click="buttonReverse2">แก้ไขการแจ้งโอน</v-btn>
          <v-btn text v-model="stateMakeSend" v-if="stateMakeSend === true" @click="buttonReverse3">แก้ไขยอดชำระ</v-btn>
          <v-btn text v-model="stateSend" v-if="stateSend === true" @click="buttonReverse4">แก้ไขข้อมูลจัดส่ง</v-btn>
        </div>
        <div>
          <!-- button state -->
          <!-- ปุ่มส่งบิล -->
          <v-btn v-if="buttonStart === true" v-model="buttonStart" color="primary" class="mx-2" @click="buttonState1">
            <v-icon class="me-1" size="18">
              {{ icons.mdiSend }}
            </v-icon>
            <span>ส่งบิล</span>
          </v-btn>
          <!-- จบปุ่มส่งบิล -->
          <!-- ปุ่มแจ้งโอน -->
          <v-btn
            v-if="buttonGoToNotPay === true"
            v-model="buttonGoToNotPay"
            color="primary"
            class="mx-2"
            @click="buttonState2"
          >
            <v-icon class="me-2" size="18">
              {{ icons.mdiCreditCardOutline }}
            </v-icon>
            <span>แจ้งโอน</span>
          </v-btn>
          <!-- จบปุ่มแจ้งโอน -->
          <!-- ปุ่มรับชำระ -->
          <v-btn
            v-if="buttonGoToPay === true"
            v-model="buttonGoToPay"
            color="primary"
            class="mx-2"
            @click="buttonState3"
          >
            <v-icon class="me-2" size="18">
              {{ icons.mdiCheckboxMarkedCircleOutline }}
            </v-icon>
            <span>รับชำระ</span>
          </v-btn>
          <!-- จบปุ่มรับชำระ -->
          <!-- ปุ่มส่งของ -->
          <v-btn
            v-if="buttonGoToMakeSend === true"
            v-model="buttonGoToMakeSend"
            color="primary"
            class="mx-2"
            @click="buttonState4"
          >
            <v-icon class="me-2" size="18">
              {{ icons.mdiTruck }}
            </v-icon>
            <span>ส่งของ</span>
          </v-btn>
          <!-- จบปุ่มส่งของ -->
          <!-- ปุ่มส่งบิล -->
          <v-btn v-if="buttonGoToSend === true" v-model="buttonGoToSend" color="primary" class="mx-2 d-none">
            <span>ส่งบิล</span>
          </v-btn>
          <!-- จบปุ่มส่งบิล -->
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiPlus,
  mdiPlusCircle,
  mdiMinusCircle,
  mdiCloseCircle,
  mdiCloudUpload,
  mdiImageAlbum,
  mdiFileDocument,
  mdiSend,
  mdiPrinter,
  mdiCreditCardOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiTruck,
} from '@mdi/js'

import AddProduct from './AddProduct.vue'

export default {
  components: {
    AddProduct,
  },
  setup() {
    const item = ['101', '102', '103', '104', '105', '106', '107', '108']
    const transit = ['เพิ่มการส่ง']
    const getDay = new Date().toISOString().slice(0, 10)

    const stateDraft = true
    const stateNotPay = false
    const statePay = false
    const stateMakeSend = false
    const stateSend = false

    const buttonStart = true
    const buttonGoToNotPay = false
    const buttonGoToPay = false
    const buttonGoToMakeSend = false
    const buttonGoToSend = false

    const minusIcons = false
    const closeIcons = true
    const itemUpload = [
      { icons: mdiCloudUpload, title: 'อัพโหลดรูป' },
      // { icons: mdiImageAlbum, title: 'เลือกจากอัลบั้ม' },
    ]
    const offset = true
    const selectTransition = ''
    const priceAdd = 0
    const discount = 0
    const price = 0
    const bankking = []
    const selectBankking = ''
    const customer = 'ชูใจ'
    const customerTel = ''
    const codeProduct = ''
    const changEditData = false
    const timePay = '18:53'
    const allPay = 150
    const address = ''
    const reason = ''

    return {
      item,
      transit,
      getDay,
      stateDraft,
      stateNotPay,
      statePay,
      stateMakeSend,
      stateSend,
      buttonStart,
      buttonGoToNotPay,
      buttonGoToPay,
      buttonGoToMakeSend,
      buttonGoToSend,
      minusIcons,
      closeIcons,
      itemUpload,
      offset,
      selectTransition,
      priceAdd,
      bankking,
      selectBankking,
      customer,
      customerTel,
      codeProduct,
      changEditData,
      discount,
      price,
      timePay,
      allPay,
      address,
      reason,
      icons: {
        mdiPlus,
        mdiPlusCircle,
        mdiMinusCircle,
        mdiCloseCircle,
        mdiCloudUpload,
        mdiImageAlbum,
        mdiFileDocument,
        mdiSend,
        mdiPrinter,
        mdiCreditCardOutline,
        mdiCheckboxMarkedCircleOutline,
        mdiTruck,
      },
    }
  },
  data: vm => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    ),
    menu1: false,
    menu2: false,
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },

  methods: {
    buttonState1() {
      this.stateDraft = false
      this.stateNotPay = true
      this.statePay = false
      this.stateMakeSend = false
      this.stateSend = false

      this.buttonStart = false
      this.buttonGoToNotPay = true
      this.buttonGoToPay = false
      this.buttonGoToMakeSend = false
      this.buttonGoToSend = false
    },
    buttonState2() {
      this.stateDraft = false
      this.stateNotPay = false
      this.statePay = true
      this.stateMakeSend = false
      this.stateSend = false

      this.buttonStart = false
      this.buttonGoToNotPay = false
      this.buttonGoToPay = true
      this.buttonGoToMakeSend = false
      this.buttonGoToSend = false
    },
    buttonState3() {
      this.stateDraft = false
      this.stateNotPay = false
      this.statePay = false
      this.stateMakeSend = true
      this.stateSend = false

      this.buttonStart = false
      this.buttonGoToNotPay = false
      this.buttonGoToPay = false
      this.buttonGoToMakeSend = true
      this.buttonGoToSend = false

      this.changEditData = false
    },
    buttonState4() {
      this.stateDraft = false
      this.stateNotPay = false
      this.statePay = false
      this.stateMakeSend = false
      this.stateSend = true

      this.buttonStart = false
      this.buttonGoToNotPay = false
      this.buttonGoToPay = false
      this.buttonGoToMakeSend = false
      this.buttonGoToSend = true

      this.changEditData = false
    },

    buttonReverse4() {
      this.stateDraft = false
      this.stateNotPay = false
      this.statePay = false
      this.stateMakeSend = true
      this.stateSend = false

      this.buttonStart = false
      this.buttonGoToNotPay = false
      this.buttonGoToPay = false
      this.buttonGoToMakeSend = true
      this.buttonGoToSend = false
    },
    buttonReverse3() {
      this.stateDraft = false
      this.stateNotPay = false
      this.statePay = true
      this.stateMakeSend = false
      this.stateSend = false

      this.buttonStart = false
      this.buttonGoToNotPay = false
      this.buttonGoToPay = true
      this.buttonGoToMakeSend = false
      this.buttonGoToSend = false
    },
    buttonReverse2() {
      this.stateDraft = false
      this.stateNotPay = true
      this.statePay = false
      this.stateMakeSend = false
      this.stateSend = false

      this.buttonStart = false
      this.buttonGoToNotPay = true
      this.buttonGoToPay = false
      this.buttonGoToMakeSend = false
      this.buttonGoToSend = false
    },
    buttonReverse1() {
      this.stateDraft = true
      this.stateNotPay = false
      this.statePay = false
      this.stateMakeSend = false
      this.stateSend = false

      this.buttonStart = true
      this.buttonGoToNotPay = false
      this.buttonGoToPay = false
      this.buttonGoToMakeSend = false
      this.buttonGoToSend = false
    },
    gotoAll() {
      const allPage = this.$router.resolve({ path: '/pages/orders' })
      window.open(allPage.href)
    },
    gotoTax() {
      const taxPage = this.$router.resolve({ path: '/pages/bill-tax' })
      window.open(taxPage.href)
    },
    gotoBill() {
      const allPage = this.$router.resolve({ path: '/pages/bill' })
      window.open(allPage.href)
    },
    gotoOpenBill() {
      const billPage = this.$router.resolve({ path: '/pages/open-bill' })
      window.open(billPage.href)
    },
    btnClickPlus(item) {
      // if (item.target.id === this.$store.state.mockData.id) {
      //   this.$store.state.mockData.amount += 1
      // }
      alert(item)
      this.closeIcons = false
      this.minusIcons = true
    },
    btnClickClose(msg) {
      alert(msg)
    },
    btnClickMinus(msg) {
      alert(msg)
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    clickEditData() {
      // this.buttonStart = true
      // this.buttonGoToNotPay = !this.buttonGoToNotPay
      this.changEditData = true
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}
img {
  height: 5.15rem;
  width: 4.235rem;
  border-radius: 50%;
  transform: translateX(-10px);
}
li {
  list-style-type: none;
}
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.testdialog {
  margin: 0 !important;
}
.mg-left {
  margin-left: 0.75rem;
}
.top_margin {
  margin-top: 0.7rem;
}
.bg-draft {
  background-color: #f8f8ff;
}
.bg-notPay {
  background-color: #f5fffa;
}
.bg-pay {
  background-color: #f0ffff;
}
.bg-makeSend {
  background-color: #f0f8ff;
}
.bg-send {
  background-color: #f0fff0;
}
.warpper_Content {
  min-height: 70vh;
  /* background-color: blue; */
}
.border_right {
  border-right: 2px solid #e5e5e5;
  margin-top: 0.7rem;
}
.wapper_content1 {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 23rem;
  /* background-color: #f5fffa; */
}
.wapperContentBottom {
  position: relative;
}
.wapperIconContent {
  transform: translateY(-100%);
  left: 32%;
  position: absolute;
}
.uploadFile {
  position: absolute;
  top: 15px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
  /* background-color: red; */
}
.style_button {
  margin-top: 2rem;
}
.imgQR {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 5px;
}
.contentQr {
  display: grid;
  justify-content: space-around;
}
.showContentmd {
  display: none !important;
}
.showMobile {
  display: none !important;
}
@media screen and (max-width: 960px) {
  .display-header {
    display: grid !important;
  }
  .pd_responsive {
    margin-bottom: 1rem;
    width: 100%;
    padding: 0 0 !important;
  }
  .mg-left {
    margin-left: 0;
  }
  .display_respon {
    display: grid !important;
    justify-content: center;
  }
  .mdRespon {
    display: none;
  }
  .showContentmd {
    display: flex !important;
  }
  .img_proauctRes {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .showContentmd {
    display: none !important;
  }
  .showMobile {
    display: flex !important;
  }
}
</style>
